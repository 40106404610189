import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [
  ChainId.SOLANA_TESTNET,
  ChainId.ETHEREUM,
  ChainId.ZKSYNC,
  ChainId.POLYGON,
  ChainId.FIL,
]
export const SUPPORT_FARMS = []

export const SUPPORT_ZAP = [] 
